<script lang="typescript">
import * as queryString from "query-string";
import {onMount} from "svelte";
import { navigate } from "svelte-routing";

  export let location;

  const queryParams = queryString.parse(location.search);

  onMount(() => {
    setInterval(() => navigate("/", { replace: true }), 5000);
  });
</script>

<style lang="scss">
</style>

<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-auto text-center">
      {#if queryParams.status == "true"}
        <span style="font-size: 8rem; color: rgb(0, 194, 80);">
          <i class="far fa-check-circle"></i>
        </span>
      {:else}
        <span class="text-danger" style="font-size: 8rem;">
          <i class="far fa-times-circle"></i>
        </span>
      {/if}
      <h1>
      Recharge 
        {#if queryParams.status == "true"}
          Successful
        {:else}
          Failed
        {/if}
      </h1>
      {#if queryParams.status == "false"}
        <h3>{queryParams.message}</h3>
      {/if}
      <p class="mt-5">Redirecting Shortly...</p>
    </div>
  </div>
</div>