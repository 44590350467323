<script lang="typescript">
  import Button from "./Button.svelte";
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher();

  let phone:string;

  let loading: boolean;

  async function verify(event) {
    if ((event == null || event.key == "Enter") && /^[0-9]{10}$/.test(phone)) {      
      loading = true;
      const result = await fetch("https://api.rideapp.in/verifyphone?phone=" + encodeURIComponent(`+91${phone}`));
      if (result.status == 200) {        
        loading = false;
        dispatch('verify', {uid: (await result.json())["uid"], phone: "+91" + phone});
      } else {
        loading = false;
        alert((await result.json())["message"]);
      }
    }
    return;
  }
</script>

<style lang="scss">
</style>

<div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-auto text-center">
        <h1 class="display-6" style="margin-bottom:32px">Verify Phone Number</h1>
        <div class="input-group input-group-lg mx-auto">
          <div class="input-group-prepend">
            <span class="input-group-text">+91</span>
          </div>
          <input type="tel" maxlength="10" placeholder="Phone number" pattern="/^[0-9]{10}$/" bind:value={phone} class="form-control validate" on:keydown={verify}>
        </div>
        <Button on:click={() => verify()}>
          {#if loading}
            <span class="spinner-border" role="status" aria-hidden="true"></span>
            Verifying...
          {:else}
            Verify
          {/if}
        </Button>
      </div>
    </div>
  </div>