<script lang="typescript">
  import Button from "./Button.svelte";
  import { fly, fade } from 'svelte/transition';

  export let uid: string;
  export let phone: string;
  let amount: string;

  function handleclick(id) {
    document.getElementById("33").classList.remove("shadow-lg");
    document.getElementById("99").classList.remove("shadow-lg")
    document.getElementById("333").classList.remove("shadow-lg")

    document.getElementById(id).classList.add("shadow-lg");

    amount = id + ".00"; 
  };

  function pay(e) {

  }
</script>

<style lang="scss">
  #top-bar {
    height: 60px;
    background: blanchedalmond;
  }

  .card{
    /* box-shadow: 0 0 10px rgba(0, 194, 80, 0.15); */
    transition: all 150ms ease-in-out;
    border-radius: 1rem;
  }

  .card:hover{
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .selected{
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .card-header{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
</style>

<div class="container">
    <h1 class="display-6 mt-5 mb-5 text-center">Select a pack</h1>
    <div class="row rwo-cols-3 justify-content-center">
      <div class="col mb-4">
        <div id="33" on:click={() => handleclick("33")} class="card text-center" style="border-color: rgb(0, 194, 80)">
          <div class="card-header border-bottom-0 text-white" style="background-color: rgb(0, 194, 80)">
            <h5>Starter Pack</h5>
          </div>
          <div class="card-body pl-5 pr-5">
            <h1 class="display-4 font-weight-bold">Rs.33</h1>
            <h5 class="font-italic">for</h5>
            <h2>10 Rides</h2>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div id="99" on:click={() => handleclick("99")} class="card border-warning text-center">
          <div class="card-header border-bottom-0 bg-warning text-white">
            <h5>Best Value</h5>
          </div>
          <div class="card-body pl-5 pr-5">
            <h1 class="display-4 font-weight-bold">Rs.99</h1>
            <h5 class="font-italic">for</h5>
            <h2>30 Rides</h2>
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div id="333" on:click={() => handleclick("333")} class="card border-primary text-center">
          <div class="card-header border-bottom-0 bg-primary text-white">
            <h5>Long Lasting</h5>
          </div>
          <div class="card-body pl-5 pr-5">
            <h1 class="display-4 font-weight-bold">Rs.333</h1>
            <h5 class="font-italic">for</h5>
            <h2>100 Rides</h2>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center">*including all charges and GST</p>
    {#if amount != null}
      <div class="text-center" in:fade="{{amount:1, duration: 250}}">
        <form method="post" action="https://api.rideapp.in/recharge" name="pay">
          <input type="hidden" name="CUST_ID" value={uid}>
          <input type="hidden" name="MOBILE_NO" value={phone}> 
          <input type="hidden" name="TXN_AMOUNT" value={amount}> 
        </form>
        <Button on:click={() => document.pay.submit()}>Pay Now</Button>
      </div>
    {/if}
  </div>