<script lang="typescript">
  import { Router, Route } from "svelte-routing"
  import Verify from "./Verify.svelte"
  import Button from "./Button.svelte"
  import Payment from "./Payment.svelte"
  import Result from "./Result.svelte"

  let details:object;
</script>

<style>
  :global(body){
    padding: 0px;
    font-family: 'Poppins', sans-serif;
  }
</style>

<Router>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/">
      <img height="24px" src="assets/logo.png" alt="Ride">
    </a>
  </nav>
  <Route path="/">
    {#if details == null}
      <Verify on:verify={(event) => details = event.detail}></Verify>
    {:else}
      <Payment {...details}/>
    {/if}
  </Route>
  <Route path="result" let:location>
    <Result location="{location}"/>
  </Route>
  <Route>
    <div class="container h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-auto text-center">
          <h1 class="display-1">404</h1>
          <h3>Page not Found</h3>
          <a href="/">
            <Button>Go home</Button>
          </a>
        </div>
      </div>
    </div>
  </Route>
</Router>

  
